import React, { useEffect, useState } from "react";
import styles from "../styles/DatePicker.module.css";
import { addDays, isSameDay, format, isSameMonth } from "date-fns";
import ScrollContainer from "react-indiana-drag-scroll";
import { CaretRightOutlined, CaretLeftOutlined } from "@ant-design/icons";

export default function DatePicker({
  activeWeekdays,
  endDate,
  selectDate,
  getSelectedDay
}) {
  const [selectedDate, setSelectedDate] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(format(new Date(), 'MMMM yyyy'));
  const buttonColor = {
    background: "#7A7585"
  };

  const onDateClick = day => {
    setSelectedDate(day);
    if (getSelectedDay) {
      getSelectedDay(day);
    }
  };

  useEffect(() => {
    if (selectDate) {
      if (!isSameDay(selectedDate, selectDate)) {
        setSelectedDate(selectDate);
        setTimeout(() => {
          let view = document.getElementsByClassName('indiana-scroll-container')[0];
          if (view) {
            view.scrollIntoView({
              behavior: "smooth",
              inline: "center",
              block: "nearest"
            });
          }
        }, 20);
      }
    }
  }, [selectDate]);

  useEffect(() => {
    const startDate = new Date();
    const endDateObj = addDays(startDate, endDate);
    const startMonth = format(startDate, 'MMMM yyyy');
    const endMonth = format(endDateObj, 'MMMM yyyy');
    setCurrentMonth(startMonth === endMonth ? startMonth : `${startMonth} - ${endMonth}`);
  }, [endDate]);

  const nextWeek = () => {
    const e = document.getElementsByClassName('indiana-scroll-container')[0];
    const width = e ? e.getBoundingClientRect().width : null;
    e.scroll({ left: e.scrollLeft === 0 ? width - 54 : e.scrollLeft + width - 54, behavior: 'smooth' });
  };

  const prevWeek = () => {
    const e = document.getElementsByClassName('indiana-scroll-container')[0];
    e.scroll({ left: e.scrollLeft - (e ? e.getBoundingClientRect().width : 0) + 54, behavior: 'smooth' });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const firstVisibleDate = new Date(entry.target.getAttribute('data-date'));
            setCurrentMonth(format(firstVisibleDate, 'MMMM yyyy'));
          }
        });
      },
      { threshold: 0.5 }
    );

    const elements = document.querySelectorAll(`.${styles.dateDayItem}`);
    elements.forEach(element => observer.observe(element));

    return () => {
      observer.disconnect();
    };
  }, []);

  const renderDays = () => {
    const days = [];
    const dayNames = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    const startDate = new Date();
    const initialMonth = format(startDate, 'MMM');
    let previousMonth = null;

    for (let i = 0; i <= endDate; i++) {
      const currentDate = addDays(startDate, i);
      const dayIndex = currentDate.getDay();
      const currentMonth = format(currentDate, 'MMM');

      if (activeWeekdays.includes(dayIndex)) {
        if (previousMonth !== currentMonth && currentMonth !== initialMonth) {
          days.push(
            <div key={`separator-${i}`} className={styles.monthSeparator}>
              |
            </div>
          );
        }

        days.push(
          <div key={i} onClick={() => onDateClick(currentDate)} className={`${styles.dateDayItem} ${isSameDay(selectedDate, currentDate) ? styles.dateDaySelected : ''}`} data-date={currentDate}>
            <div>{dayNames[dayIndex]}</div>
            <div>{format(currentDate, 'd')}</div>
          </div>
        );

        previousMonth = currentMonth;
      }
    }

    return days;
  };

  return (
    <>
      <div className={styles.monthHeader}>{currentMonth}</div>
      <div className={styles.container}>
        <div className={styles.buttonWrapper}>
          <button className={styles.button} style={buttonColor} onClick={prevWeek}>
            <CaretLeftOutlined />
          </button>
        </div>
        <ScrollContainer className={styles.dateListScrollable}>
          {renderDays()}
        </ScrollContainer>
        <div className={styles.buttonWrapper}>
          <button className={styles.button} style={buttonColor} onClick={nextWeek}>
            <CaretRightOutlined />
          </button>
        </div>
      </div>
    </>
  );
}
