import React, { useState } from 'react';
import Styled from 'styled-components';
import styles from '../styles/RadioGroup.module.css';
import '../App.css';

function CustomRadioGroup({ 
  direction, 
  defaultValue, 
  question, 
  disabled = false, 
  onSelectItem 
}) {
  const [selectedElement, setSelectedElement] = useState(null);

  function onChange(ev) {
    setSelectedElement(ev);
    if (onSelectItem) onSelectItem(ev); // RETURN RESPONSE TO PARENT
  }

  function renderFields() {
    const fields = question.question_options
      .sort((a, b) => a.order - b.order)
      .map((opt) => {
      return (
        <div 
          key={opt.id} 
          className={`${styles.selectStyle} ${selectedElement === opt ? styles.selectedItem : null}`}
          style={{...(direction === 'horizontal' ? horizontalMaxWidth : null)}} 
          onClick={() => onChange(opt)}
        >
          {opt.value}
        </div>
      )
    });

    return fields;
  }

  return (
    <ContainerRadioGroup disabled={disabled}>
      <h3 className={styles.titleStyle}>
        {question.label}
      </h3>
      <h5 className={styles.subtitleStyle}>
        Select option
      </h5>
      <div>
        <div className={styles.containerRadioFields}>
          { renderFields() }
        </div>
      </div>
    </ContainerRadioGroup>
  )
}

const horizontalMaxWidth = {
  minWidth: 0,
  flex: '1 0 45%'
}

const ContainerRadioGroup = Styled.div`
  transition: all .3s;
  ${props => props.disabled ? "opacity: .3; pointer-events: none;" : ""}
}`;

export default CustomRadioGroup;