import React from 'react';
import moment from 'moment';

import { CalendarOutlined } from '@ant-design/icons';

import styles from "./MeetingConfirmation.module.css";

const MeetingConfirmation = ({ corporateColor, userEmployeeImage, scheduleContainer, userEmployeeFullName, eventDate, eventHour }) => {
  return (
    <div className={styles.meetingConfirmationContainer}>
      <div style={{marginBottom: 50}}>
        <div style={{fontSize: 20, marginBottom: 10}}>Thank you for reaching out!</div>
        <div style={{fontSize: 22, lineHeight: 1.2, fontFamily: 'RedHatDisplay-Medium', color: corporateColor}}>You have booked a 30 minute meeting</div>
      </div>
      <div style={{marginBottom: 35}}>
        <div style={{marginBottom: 80}}>
          <div style={{fontSize: 18, marginBottom: 30}}>Your meeting with</div>
          <div>
            <img 
              style={{borderRadius: '100%', height: 140, width: 140}}
              src={userEmployeeImage || `https://ui-avatars.com/api/?name=${userEmployeeFullName?.replace(' ', '+')}&background=4ace67&color=fff`}
              alt="user-employee-meeting"/>
          </div>
          <div style={{fontSize: 20, lineHeight: 1.2, color: 'black', fontFamily: 'RedHatDisplay-Semibold', marginTop: 10}}>{userEmployeeFullName}</div>
        </div>
        <div style={scheduleContainer}>
          <div style={{fontSize: 18, marginBottom: 5}}>is Scheduled</div>
          <div style={{fontSize: 20, lineHeight: 1.2, color: corporateColor, fontFamily: 'RedHatDisplay-Semibold'}}>
            <CalendarOutlined style={{marginRight: 8}} /> { moment(eventDate).format('MMMM Do') }, { eventHour.hour } - { moment(eventHour.hour, 'HH:mm').add(30, 'minutes').format('HH:mm') }
          </div>
        </div>
      </div>
    </div>
  )
}

export default MeetingConfirmation;
