import React from 'react';

import { CheckCircleFilled } from '@ant-design/icons';
import styles from "./MeetingContactSoon.module.css";

const MeetingContactSoon = ({ color }) => {
  return (
    <div className={styles.meetingContactSoonCard}>
      <div style={{fontSize: 70, color: color, marginBottom: 40}}>
        <CheckCircleFilled />
      </div>
      <div>
        <h4 style={{marginBottom: 30}}>Thank you for contacting us</h4>
        <h2>A member of our sales team will contact you soon</h2>
      </div>
    </div>
  )
}

export default MeetingContactSoon;
