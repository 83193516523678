import React, { useEffect, useState } from "react";
import styles from "../styles/HourPicker.module.css";
import { Skeleton } from "antd";
import { StopOutlined } from '@ant-design/icons';

export default function HourPicker({
  dateSelected, // DATE SELECTED TO GET DISABLED HOURS
  availableHours, // HIDE NON WORKING HOURS IN FORMAT ['XX:XX', 'XX:XX']
  defaultHour, // DEFAULT HOUR SELECTED, CAN BE NULL
  getHourSelected, // RETURN THE HOUR SELECTED TO THE PARENT
}) {
  const [selectedHour, setSelectedHour] = useState();

  useEffect(() => {
    setSelectedHour(defaultHour);
  }, [defaultHour]);

  const selectHour = (hour) => {
    setSelectedHour(hour);
    getHourSelected(hour);
  };

  const hourCorporateColor = styles.hourSelected

  function renderHour(hour) {
    const hourClass = `${styles.hour} ${
      selectedHour === hour ? hourCorporateColor : null
    } ${!hour.available ? styles.hourDisabled : null}`;
  
    return (
      <div
        key={hour.id}
        onClick={() => selectHour(hour)}
        className={hourClass}
      >
        {hour.hour}
      </div>
    );
  }

  function renderNoAvailableHours() {
    return (
      <div className={styles.notAvailableHoursMessage}>
        <StopOutlined />
        <p>Not available hours for this date</p>
      </div>
    );
  }
  
  function renderSkeleton() {
    return new Array(9).fill(0).map((a, index) => (
      <div
        key={index}
        style={{
          minWidth: 110,
          maxWidth: 144,
          height: 40,
          flex: "0 1 32%",
        }}
      >
        <Skeleton.Button size={"default"} shape={"square"} block />
      </div>
    ));
  }

  return (
    <div className={styles.hoursContainer}>
      {dateSelected 
        ? availableHours.length > 0 
          ? availableHours.map(renderHour) 
          : renderNoAvailableHours()
        : renderSkeleton()}
    </div>
  );
}
