import React from 'react';
import { Element } from 'react-scroll';
import { Button } from 'antd';

import HourPicker from '../hour-picker';
import Meeting from '../meeting';
import DatePicker from '../date-picker';

import logoWeyzo from '../../assets/images/logo_weyzo.png';

const MeetingSchedule = (props) => {
  const { 
    containerStep, 
    formLogo, 
    corporateColor, 
    dateSelected, 
    selectHour, 
    eventDate, 
    availableHours,
    workingHours,
    eventHour, 
    companyUser, 
    primaryColor, 
    submitMeeting 
  } = props;
  
  const filterWorkingDays = () => {
    return workingHours.map(wh => parseInt(wh.weekday));
  };

  const activeWeekdays = filterWorkingDays();

  return (
    <Element name="container-step">
      <div ref={containerStep}>
        <div style={{width: '100%', textAlign: 'center', marginBottom: 20}}>
          { formLogo !== '' ? (
            <img src={`${process.env.REACT_APP_HOST_NAME}${formLogo}`} style={{width: '50%'}} alt="company logo" />
          ) : (
            <img src={logoWeyzo} style={{width: '50%'}} alt="company logo" />
          )}
        </div>
        
        <div style={{marginBottom: 40}}>
          <h2 style={{textAlign: 'center'}}>Schedule a Call | 30 minutes</h2>
          <h3 style={{textAlign: 'center', marginBottom: 20, color: corporateColor, fontSize: 20}}>What time works best for you?</h3>

          <DatePicker
            activeWeekdays={activeWeekdays}
            endDate={100}
            getSelectedDay={date => dateSelected(date)}
          />
        </div>
        
        {/* <div ref={containerHours}> */}
        <Element name="container-hours">
          <HourPicker 
            getHourSelected={(hour) => selectHour(hour)}
            dateSelected={eventDate}
            availableHours={availableHours}
            defaultHour={eventHour || null}
          />
        </Element>
        <div>
          <Meeting image={`${process.env.REACT_APP_HOST_NAME}/${companyUser.image_url}` || `https://ui-avatars.com/api/?name=${companyUser.fullName?.replace(' ', '+')}&background=4ace67&color=fff`} endTime={30} eventDate={eventDate ? eventDate : null} eventHour={eventHour ? eventHour.hour : null} meetingName={companyUser.full_name} />
          <Button 
            size='large'
            type="primary"
            style={{borderRadius: 8, border: 'none', fontWeight: '700', backgroundColor: primaryColor}}
            disabled={eventDate && eventHour ? false : true}
            onClick={() => submitMeeting()}
            block> 
            Book call
          </Button>
        </div>
      </div>
    </Element>
  )
}

export default MeetingSchedule;
