import React, { createRef, useEffect, useRef, useState } from 'react';
import { scroller } from 'react-scroll';
import { animateScroll } from 'react-scroll/modules';
import { Card } from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';

import { createLead, updateLead, createEvent, getByDomain } from './api/weyzo.api';

import CustomSpinner from './components/custom-spinner';
import Watermark from './components/Watermark/Watermark';
import FormQuestions from './components/FormQuestions/FormQuestions';
import MeetingContactSoon from './components/MeetingContactSoon/MeetingContactSoon';
import MeetingSchedule from './components/MeetingSchedule/MeetingSchedule';
import MeetingConfirmation from './components/MeetingConfirmation/MeetingConfirmation';

import logoWeyzo from './assets/images/logo_weyzo.png';
import styles from './styles/App.module.css';

function App() {
  const containerStep = useRef();
  const answersSteps = useRef([]);

  // DEFINITIONS
  const [formState, setFormState] = useState({});
  const [companyUser, setCompanyUser] = useState(null);
  const [allCompanyUsers, setAllCompanyUsers] = useState()
  const [leadState, setLeadState] = useState(null);
  const [formStep, setFormStep] = useState(0);
  const [eventDate, setEventDate] = useState(null);
  const [availableHours, setAvailableHours] = useState([]);
  const [eventHour, setEventHour] = useState(null);
  const [formScore, setFormScore] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [fixedQuestionValues, setFixedQuestionValues] = useState({
    fullName: { value: '', error: false },
    workEmail: { value: '', error: false },
    jobTitle: { value: '', error: false },
    phone: { value: '', error: false },
    country: { value: '', error: false }
  });

  useEffect(() => {
    let interval = null;
    const hasValue = fixedQuestionValues && Object.values(fixedQuestionValues).some(item => item.value);

    if (hasValue) {
      interval = setInterval(() => {
        createUpdateLead();
        console.log('leadState useEffect', leadState)
        clearInterval(interval);
      }, 600);
    }

    return() => {if (interval) clearInterval(interval)}
  }, [fixedQuestionValues]);

  useEffect(() => {
    if (formState.fixedQuestions && formState.fixedQuestions.length > 0) {
      const initialQuestionValues = formState.fixedQuestions.reduce((acc, question) => {
        acc[question.name] = { value: '', error: false };
        return acc;
      }, {});
  
      setFixedQuestionValues(initialQuestionValues);
    }
  }, [formState.fixedQuestions]);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const { form } = await getByDomain();
        setCompanyUser(form.user)
        setAllCompanyUsers([form.user])
        setFormState({
          id: form.id,
          user: form.user,
          title: form.title,
          subtitle: form.subtitle,
          primaryColor: form.primary_color,
          secondaryColor: form.secondary_color,
          buttonText: form.button_text,
          buttonBackground: form.button_background,
          buttonTextColor: form.button_text_color,
          meetingDuration: form.meeting_duration,
          legalText: form.legal_text,
          logoUrl: form.logo_url,
          videoCall: form.video_call,
          watermarkDisabled: form.watermark_disabled,
          fixedQuestions: form.fixed_questions,
          customFixedQuestions: form.custom_fixed_questions,
          qualifyingQuestions: form.qualifying_questions
        })
        getURLParams();

        setAnswers(form.id, form.qualifying_questions);
        setIsLoading(false);
      } catch(err) {
        console.log(err)
        setShowToast(true);
        setIsLoading(false);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    setFormSteps();

    return() => {}
  }, [formStep]);

  const setAnswers = (formId, formQualifyingQuestions) => {
    if (!formId) return;
    answersSteps.current = Array(formQualifyingQuestions.length).fill().map(element => createRef());
  }

  const setFormSteps = () => {
    if (formStep > 0 && formStep < 2) {
      scroller.scrollTo('container-step', {
        delay: 0,
        duration: 800,
        smooth: 'linear'
      });
    }
  }

  const getURLParams = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const name = queryParams.get('name');
    const email = queryParams.get('email');
    const phone = queryParams.get('phone');

    if (name !== null) setFixedQuestionValues(prev => ({...prev, name: { value: name, error: false } }));
    if (email !== null) setFixedQuestionValues(prev => ({...prev, workEmail: { value: email, error: false } }));
    if (phone !== null) setFixedQuestionValues(prev => ({...prev, phone: { value: phone, error: false } }));
  }

  // CREATE AND UPDATE LEADS
  const createUpdateLead = async () => {
    let lead = {
      name: fixedQuestionValues.fullName?.value || '',
      email: fixedQuestionValues.workEmail?.value || '',
      phone: fixedQuestionValues.phone?.value || '',
      position: fixedQuestionValues.jobTitle?.value || '',
      country: fixedQuestionValues.country?.value || ''
    }

    if (!leadState) {
      await createLead(lead.name, lead.email, lead.phone, lead.position, lead.country, formState.id)
        .then(async (response) => {
          await setLeadState(response.lead);
        }).catch((err) => {
          console.error('CREATE LEAD ERROR', err);
        });
    } else {
      await updateLead(lead.name, lead.email, lead.phone, lead.position, lead.country, formState.id, leadState.id)
        .then(async (response) => {
          await setLeadState(response.lead);
        }).catch((err) => {
          console.error('UPDATE LEAD ERROR', err);
        })
    }
  }

  // SELECT DATE FROM CALENDAR
  const dateSelected = async (date) => {
    setEventDate(date);
    
    const weekday = moment(date).weekday();
    const workingHours = companyUser.working_hours;
    const dailyWorkingHours = workingHours.find(wh => wh.weekday === weekday.toString());
    
    if (dailyWorkingHours) {
      const availableHours = generateAvailableHours([dailyWorkingHours], companyUser.meetings, formState.meetingDuration, date);      
      await setAvailableHours(availableHours);
    } else {
      console.log('No working hours configured for this weekday');
      await setAvailableHours([]);
    }
    
    scroller.scrollTo('container-hours', {
      delay: 0,
      duration: 800,
      smooth: 'linear'
    });
    setEventHour(null);
  }

  const generateAvailableHours = (workingHours, meetings, meetingDuration, eventDate) => {
    const availableHours = [];
  
    workingHours.forEach(({ weekday, start_time, end_time }) => {
      const start = moment(start_time, "HH:mm");
      const end = moment(end_time, "HH:mm");
      const difference = end.diff(start, "minutes");
      const iterationTimes = difference / meetingDuration;
  
      for (let i = 0; i <= iterationTimes; i++) {
        const hour = moment(start).add(i * meetingDuration, "minutes").format("HH:mm");
        const available = !meetings.some(({ date }) => 
          moment(date).format('DD-MM-YYYY') === moment(eventDate).format('DD-MM-YYYY') && 
          moment(date).format('HH:mm') === hour
        );
        availableHours.push({
          weekday,
          hour,
          available,
        });
      }
    });
  
    return availableHours;
  };

  // SELECT HOUR FROM THE LIST
  const selectHour = async (hour) => {
    await setEventHour(hour);
    animateScroll.scrollToBottom();
  }

  // SEND MEETING DATA TO API
  const submitMeeting = () => {
    const selectedDate = `${moment(eventDate).format('DD/MM/YYYY')} ${eventHour.hour}`;
    const date = moment(selectedDate, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ssZ');

    createEvent(date, `Meeting with ${fixedQuestionValues.fullName?.value} / ${fixedQuestionValues.workEmail?.value} / ${fixedQuestionValues.phone?.value}`, companyUser.id, leadState.id, formState.id)
    .then(() => {
      setFormStep(2)
    }).catch((err) => {
      console.error(err)
    });
  }

  const corporateColor = '#8847E6'

  if (window.matchMedia("(max-width: 768px)").matches) {
    document.body.style.backgroundColor = '#FFFFFF';
  } else {
    document.body.style.backgroundColor = formState.secondaryColor || '#FCF9F0';
  }

  return (
    <>
      <Card
        bordered={false} 
        className={styles.formMainCard}>
        { showToast &&
          <div className={styles.platformNotAvailableContainer}>
            Platform not available at the moment
          </div> 
        }
        { isLoading && <CustomSpinner /> }
        { formStep === 0 &&
          <FormQuestions
            formId={formState.id}
            formTitle={formState.title}
            formSubtitle={formState.subtitle}
            formLegalText={formState.legalText}
            primaryColor={formState.primaryColor}
            formLogo={formState.logoUrl}
            enableVideoCall={formState.videoCall}
            buttonText={formState.buttonText}
            buttonBackgroundColor={formState.buttonBackground}
            buttonTextColor={formState.buttonTextColor}
            leadState={leadState}
            fixedQuestionValues={fixedQuestionValues}
            setFixedQuestionValues={setFixedQuestionValues}
            fixedQuestions={formState.fixedQuestions}
            customFixedQuestions={formState.customFixedQuestions}
            qualifyingQuestions={formState.qualifyingQuestions}
            answersSteps={answersSteps}
            setFormStep={setFormStep}
            setFormScore={setFormScore}
            setIsLoading={setIsLoading}
          />
        }
        { formStep === 1 && 
          <MeetingSchedule
            containerStep={containerStep}
            formLogo={formState.logoUrl}
            corporateColor={corporateColor}
            dateSelected={dateSelected}
            selectHour={selectHour}
            eventDate={eventDate}
            availableHours={availableHours}
            workingHours={companyUser.working_hours}
            eventHour={eventHour}
            companyUser={companyUser}
            primaryColor={formState.primaryColor}
            submitMeeting={submitMeeting}
          />
        }
        { formStep === 2 &&
          <div>
            { formScore > 0 &&
              <MeetingConfirmation 
                corporateColor={corporateColor}
                companyUserImage={companyUser.image}
                scheduleContainer={scheduleContainer}
                companyUserFullName={companyUser.fullName}
                eventDate={eventDate}
                eventHour={eventHour}
              />
            }
            { formScore <= 0 &&
              <MeetingContactSoon color={formState.secondaryColor} />
            }
          </div>
        }
        { !formState.disabledWatermark && ( 
          <Watermark logo={logoWeyzo} />
        )}
      </Card>
    </>
  );
}

const scheduleContainer = {
  backgroundColor: 'white', 
  borderRadius: 10, 
  padding: 20,
  boxShadow: '0px 10px 16px rgba(20, 37, 63, 0.06)'
}

export default App;
