import moment from "moment";
import { Skeleton } from "antd";
import styles from "./MeetingSchedule/MeetingSchedule.module.css";

export default function Meeting({
  image,
  eventDate,
  eventHour,
  meetingName,
  endTime, // END TIME IN MINUTES
}) {
  const corporateColor = "#8847E6";

  return (
    <div
      className={styles.meetingContainer}
    >
      {eventHour ? (
        <>
          <div style={{ marginRight: 15 }}>
            <img
              style={{ borderRadius: "100%", height: 60, width: 60 }}
              src={image}
              alt="user-employee-meeting"
            />
          </div>
          <div style={{ color: "#212427" }}>
            <div>Meet {meetingName}</div>
            <div style={{ color: corporateColor, fontSize: 18 }}>
              {moment(eventDate).format("MMMM Do")} {eventHour} -{" "}
              {moment(eventHour, "HH:mm").add(endTime, "m").format("HH:mm")}
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className={styles.skeletonWrapper}
          >
            <Skeleton.Avatar
              size={"large"}
              shape={"circle"}
              style={{ marginRight: 20 }}
            />
            <div className={styles.skeletonTextContainer}>
              <Skeleton
                className={styles.meetingSkeletonParagraph}
                style={{ marginBottom: 15 }}
                paragraph={{ width: "100%", rows: 1 }}
                title={false}
              />
              <Skeleton
                className={styles.meetingSkeletonParagraph}
                paragraph={{ width: "100%", rows: 1 }}
                title={false}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
