import React from "react";
import { Input } from "antd";

function CustomInput({
  label,
  question,
  onChange,
  isError,
  data,
  changeEvent,
}) {
  return (
    <>
      {label && <label> {label} </label>}
      <Input
        value={data}
        placeholder={question.placeholder + (question.required ? " *" : "")}
        type={question.value_type}
        onBlur={changeEvent}
        onChange={onChange}
        style={isError ? inputError : inputStyle}
      />
      {/* ERROR LABEL */}
      {isError ? (
        <p style={alertStyle}>
          Please enter a valid entry
        </p>
      ) : null}
    </>
  );
}

const inputStyle = {
  borderRadius: 8,
  borderColor: "#CBD5E0",
  marginTop: 5,
};

const inputError = {
  borderRadius: 8,
  fontWeight: 600,
  borderColor: "#d32f2f",
  marginTop: 5,
  boxShadow: "none",
};

const alertStyle = {
  color: "#d32f2f",
  paddingTop: 3,
  paddingBottom: 3,
};

export default CustomInput;
