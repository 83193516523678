import axios from 'axios';
const BASE_URL = process.env.REACT_APP_HOST_NAME;
const weyzoInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
})

// GET form data by domain
export const getByDomain = () => {
  return new Promise((resolve, reject) => {
    try {
      const pathname = window.location.pathname; // Get the pathname
      const domainSlug = pathname.startsWith('/') ? pathname.slice(1) : pathname; // Remove leading slash if present
      weyzoInstance.get(`public/forms/${domainSlug}`).then(
        (res) => {
          resolve(res.data);
        },
        (err) => {
          console.error(err);
          reject();
        }
      );
    } catch (err) {
      console.error(err);
      reject();
    }
  });
};

// CREATE Lead
export const createLead = (userName, userEmail, userPhone, position, country, formId) => {
  let leadData = {
    'name': userName,
    'email': userEmail,
    'phone': userPhone,
    'position': position,
    'country': country,
  };

  return new Promise((resolve, reject) => {
    weyzoInstance.post(`public/forms/${formId}/leads`, { lead: leadData })
      .then((result) => {
        resolve(result.data);
      }).catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};

// UPDATE Lead
export const updateLead = (userName, userEmail, userPhone, position, country, formId, leadId) => {
  let leadData = {
    'name': userName,
    'email': userEmail,
    'phone': userPhone,
    'position': position,
    'country': country
  };

  return new Promise((resolve, reject) => {
    weyzoInstance.patch(`public/forms/${formId}/leads/${leadId}`, { lead: leadData })
      .then((result) => {
        resolve(result.data);
      }).catch((err) => {
        console.error(err);
        reject(err);
      });
  });
}

// CREATE Lead Answer
export const createLeadAnswer = (leadId, formId, questionId, questionOptionId, answerValue) => {
  let leadData = {
    'question_id': questionId,
    'question_option_id': questionOptionId,
    'answer_value': answerValue
  }

  return new Promise((resolve, reject) => {
    weyzoInstance.post(`/public/forms/${formId}/leads/${leadId}/lead_answers`, { lead_answer: leadData })
    .then((result) => {
      resolve(result.data);
    }).catch((err) => {
      console.error(err);
      reject(err);
    });
  });
}

// UPDATE Lead Answer
export const updateLeadAnswer = (leadId, formId, questionId, questionOptionId, answerValue, leadAnswerId) => {
  let leadData = {
    'question_id': questionId,
    'question_option_id': questionOptionId,
    'answer_value': answerValue,
  }

  return new Promise((resolve, reject) => {
    weyzoInstance.patch(`/public/forms/${formId}/leads/${leadId}/lead_answers/${leadAnswerId}`, { lead_answer: leadData })
    .then((result) => {
      resolve(result.data);
    }).catch((err) => {
      console.error(err);
      reject(err);
    });
  });
}

export const qualifyLead = (leadId, formId) => {
  return new Promise((resolve, reject) => {
    weyzoInstance.patch(`/public/forms/${formId}/leads/${leadId}/qualify`)
    .then((res) => {
      resolve(res.data);
    }, (err) => {
      console.error(err);
      reject();
    })
  });
}

export const getAllEvents = (userId) => {
  return new Promise((resolve, reject) => {
    weyzoInstance.get(`user_calendar/${userId}`)
      .then((res) => {
        resolve(res.data);
      }, (err) => {
        console.error(err);
        reject(err);
      });
  });
}

// CREATE EVENT WITH DATE SELECTED
export const createEvent = (date, description, userId, leadId, formId) => {
  return new Promise((resolve, reject) => {
    const meetingData = { date: date, description: description, user_id: userId, lead_id: leadId, form_id: formId };
    resolve(meetingData)
    weyzoInstance.post(`/public/forms/${formId}/leads/${leadId}/meetings`, { meeting: meetingData })
      .then((res) => {
        resolve(res.data);
      }, (err) => {
        console.error(err);
        reject(err);
      });
  });
}

// GET FORMID FROM THE DOMAIN
export const getQualification = (leadId) => {
  return new Promise((resolve, reject) => {
    weyzoInstance.post('get_qualification', { lead_id: leadId })
      .then((res) => {
        resolve(res.data);
      }, (err) => {
        console.error(err);
        reject();
      })
  });
}
